<app-navbar-style-three></app-navbar-style-three>
<div class="portfolio-agency-banner1 pa-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container mt-80">
                <div class="banner-content text-center">
                    <h1>Contactez Nous</h1>
                </div>
                <div class="page-title-content">
                    <ul>
                        <li><a routerLink="/">Acceuil</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Start Contact Area -->
<section class="contact-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-email"></i>
                    </div>
                    <h3>Email</h3>
                    <p>
                        <a href="mailto:hello@karlson.com"
                            >s.rhaba@expresssrh.com</a
                        >
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-marker"></i>
                    </div>
                    <h3>Localisation</h3>
                    <p>
                        <a
                            href="https://goo.gl/maps/Mii9keyeqXeNH4347"
                            target="_blank"
                            >110 A Rue de la mairie 01480 Jassans Riottier</a
                        >
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-phone-call"></i>
                    </div>
                    <h3>Télephone</h3>
                    <p><a href="tel:1234567890">+33 6 60 84 11 61</a></p>
                </div>
            </div>
        </div>

        <div class="section-title">
            <span class="sub-title">CONTACTEZ-NOUS</span>
            <h2>Envoyez-nous un message pour vous expliquez tout</h2>
            <p>
                Vous souhaitez être tenu au courant des dernières nouvelles
                Inscrivez-vous à notre newsletter dès maintenant et recevez
                toutes les informations importantes dans votre boîte e-mail.
            </p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-4 col-md-4">
                <div class="contact-image">
                    <img src="assets/img/delivery1.png" alt="image" />
                </div>
            </div>

            <div class="col-lg-8 col-md-8">
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        placeholder="Nom"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        placeholder="Email"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="phone_number"
                                        id="phone_number"
                                        class="form-control"
                                        placeholder="Téléphone"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="msg_subject"
                                        id="msg_subject"
                                        class="form-control"
                                        placeholder="Sujet"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea
                                        name="message"
                                        class="form-control"
                                        id="message"
                                        cols="30"
                                        rows="5"
                                        placeholder="Votre Message"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">
                                    Envoyer un Message <span></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
