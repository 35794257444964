<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="portfolio-agency-banner1 pa-bg5 shadow">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Nos Services Médicaux</h2>
                    <ul>
                        <li><a routerLink="/">Acceuil</a></li>
                        <li>Service Médical</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>Les Services de Livraison Médicale</h2>
                    <p>
                        Les services de livraison médicale offrent une solution
                        essentielle pour assurer la distribution rapide et
                        sécurisée de produits médicaux vitaux.
                    </p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class="bx bxs-badge-check"></i>
                                Livraison Rapide de Médicaments
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class="bx bxs-badge-check"></i>
                                Distribution de Fournitures Médicales
                                Essentielles
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class="bx bxs-badge-check"></i>
                                Livraison Sécurisée d'Équipements Médicaux
                                Spécialisés
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class="bx bxs-badge-check"></i>
                                Transport de Produits Pharmaceutiques Sensibles
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class="bx bxs-badge-check"></i>
                                Livraison de Matériel Médical à Domicile
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class="bx bxs-badge-check"></i>
                                Livraison sous température contrôlée
                            </span>
                        </li>
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/img8.jpg" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Page Title Area -->
<section class="services-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Comment on travaille ?</span>
            <h2>Les étapes des livraisons sous température contrôlée</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/step-1.png" alt="team" />
                    </div>
                    <div class="content">
                        <h3>Étape 1</h3>
                        <span
                            >Activer et placer l'Enregistreur dans le colis à
                            envoyer</span
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/step2.png" alt="team" />
                    </div>
                    <div class="content">
                        <h3>Étape 2</h3>
                        <span
                            >Surveillance en continu pendant le transport.</span
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/step3.png" alt="team" />
                    </div>
                    <div class="content">
                        <h3>Étape 3</h3>
                        <span
                            >Consultation immédiate des données et documentation
                            conforme aux directives.</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Start Services Area -->
<section class="pt-100 pb-70 gray-bg">
    <div class="container">
        <div class="section-title">
            <h2>Nos Services</h2>
            <p>Voici nos services de livraison médicale</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class="bx bx-capsule"></i>
                        <i class="bx bxs-badge-check check-icon"></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details"
                            >Livraison de Médicaments
                        </a>
                    </h3>
                    <p>
                        Service de livraison rapide et sécurisée de médicaments
                        aux pharmacies, hôpitaux et établissements de santé.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class="bx bxs-briefcase-alt"></i>
                        <i class="bx bxs-badge-check check-icon"></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details"
                            >Distribution de Matériel Médical
                        </a>
                    </h3>
                    <p>
                        Fourniture et livraison de matériel médical, équipements
                        et dispositifs médicaux aux professionnels de la santé.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class="bx bxs-thermometer"></i>
                        <i class="bx bxs-badge-check check-icon"></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details"
                            >Livraison sous température contrôlée</a
                        >
                    </h3>
                    <p>
                        service spécialisé de livraison de sang sous température
                        contrôlée, garantissant l'intégrité et la qualité du
                        produit.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->
