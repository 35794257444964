<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="portfolio-agency-banner1 pa-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Nos services de transport</h2>
                    <ul>
                        <li><a routerLink="/">Acceuil</a></li>
                        <li>Service transport</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Why Choose Us Area -->
<section class="why-choose-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-content">
                    <h2>Pourquoi choisir Express SRH ?</h2>
                    <p>
                        Chez Express SRH, nous sommes fiers de fournir des
                        services de livraison fiables et efficaces. Nous nous
                        engageons à fournir des solutions sur mesure pour
                        répondre aux besoins uniques de chaque client, en nous
                        assurant que chaque livraison est effectuée avec
                        précision et ponctualité.
                    </p>

                    <div class="features-text">
                        <h4>
                            <i class="flaticon-tick"></i> La priorité chez
                            Express SRH
                        </h4>
                        <p>
                            Nous mettons l'accent sur la sécurité et la
                            fiabilité de chaque livraison, et nous nous
                            efforçons de maintenir une communication claire avec
                            nos clients tout au long du processus.
                        </p>
                    </div>

                    <div class="features-text">
                        <h4>
                            <i class="flaticon-tick"></i> La tranquillité
                            d'esprit et la garantie
                        </h4>
                        <p>
                            Avec notre équipe de professionnels expérimentés et
                            nos technologies de pointe, vous pouvez avoir la
                            tranquillité d'esprit en sachant que vos colis sont
                            entre de bonnes mains.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-image">
                    <img src="assets/img/img6.jpg" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- End Why Choose Us Area -->

<!-- Start Services Area -->

<section class="pt-100 pb-70 gray-bg">
    <div class="container">
        <div class="section-title">
            <h2>Nos Services</h2>
            <p>Voici nos services de livraison</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class="bx bx-check"></i>
                        <i class="bx bxs-badge-check check-icon"></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">
                            Livraison Express :</a
                        >
                    </h3>
                    <p>
                        Express SRH se spécialise dans les livraisons rapides et
                        urgentes. Ils garantissent une livraison rapide et
                        efficace à destination.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class="bx bx-stopwatch"></i>
                        <i class="bx bxs-badge-check check-icon"></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details"
                            >Livraison le jour même</a
                        >
                    </h3>
                    <p>
                        Express SRH propose des services de livraison le jour
                        même, répondant ainsi aux besoins des clients
                        nécessitant une expédition immédiate de leurs articles.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class="bx bxs-been-here"></i>
                        <i class="bx bxs-badge-check check-icon"></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">
                            Suivi et traçabilité :</a
                        >
                    </h3>
                    <p>
                        Express SRH propose des services de suivi et de
                        traçabilité, permettant aux clients de suivre en temps
                        réel le progrès de leurs expéditions.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->
