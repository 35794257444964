<!-- Start Footer Area -->
<section class="footer-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-phone-call"></i>
                            <span>7J/7 24H/24</span>
                            <a href="tel:+1235421457852">+33 6 60 84 11 61</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>Envoyez nous un email</span>
                            <a href="mailto:hello@karlson.com"
                                >s.rhaba@expresssrh.com</a
                            >
                        </li>
                        <li>
                            <i class="flaticon-social-media"></i>
                            <span>Réseaux sociaux</span>
                            <ul class="social">
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="fab fa-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="fab fa-facebook-f"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="fab fa-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="fab fa-linkedin"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="fab fa-youtube"></i
                                    ></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Liens Rapides</h3>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Acceuil</a></li>
                        <li><a routerLink="/contact">Contact</a></li>

                        <li>
                            <a routerLink="/services">
                                Trasport de marchandise</a
                            >
                        </li>
                        <li>
                            <a routerLink="/services"> Trasport médical</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>
                        Copyright @{{ currentYear }}
                        <a href="https://envytheme.com/" target="_blank"
                            >Express SRH</a
                        >. All rights reserved
                    </p>
                </div>
                <!-- <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</section>
<!-- End Footer Area -->
