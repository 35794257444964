<app-navbar-style-three></app-navbar-style-three>
<!-- Start Main Banner Area -->
<div class="portfolio-agency-banner3 pa-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container mt-80">
                <div class="banner-content">
                    <h1 id="green">
                        Express
                        <span id="yellow">SRH</span>
                        <img
                            src="assets/img/logo2.png"
                            alt="logo"
                            style="width: 7%"
                        />
                    </h1>

                    <div class="banner-btn">
                        <h4>Livraison rapide et fiable</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Services Area -->
<section class="featured-services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">NOS SERVICES</span>
            <h2>Express SRH Services</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-featured-box home-text-ul">
                    <div class="icon color-1ac0c6">
                        <i class="fa-solid fa-truck"></i>
                    </div>
                    <h3>Transport de marchandise</h3>
                    <ul class="features-list">
                        <li><i class="flaticon-tick"></i>Transport Rapide</li>
                        <li><i class="flaticon-tick"></i>Transport Planifié</li>
                        <li><i class="flaticon-tick"></i>Transport Adapté</li>
                    </ul>
                    <a routerLink="/services" class="default-btn"
                        >En savoir plus<span></span
                    ></a>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box home-text-ul">
                    <div class="icon color-facd60">
                        <i class="fa-solid fa-boxes-stacked"></i>
                    </div>
                    <h3>Colis</h3>
                    <ul class="features-list">
                        <li>
                            <i class="flaticon-tick"></i>Livraison le Jour Même
                        </li>
                        <li>
                            <i class="flaticon-tick"></i>Livraison de Dernier
                            Kilomètre
                        </li>
                        <li><i class="flaticon-tick"></i>Suivi de colis</li>
                    </ul>
                    <a routerLink="/services" class="default-btn"
                        >En savoir plus<span></span
                    ></a>
                </div>
            </div> -->

            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-featured-box home-text-ul">
                    <div class="icon color-facd60">
                        <i class="fa-solid fa-suitcase-medical"></i>
                    </div>
                    <h3>Transport médical</h3>
                    <ul class="features-list">
                        <li>
                            <i class="flaticon-tick"></i>Distribution des
                            Médicaments
                        </li>
                        <li>
                            <i class="flaticon-tick"></i>Livraison sous
                            Température Contrôlée
                        </li>
                        <li>
                            <i class="flaticon-tick"></i>Transport des produits
                            sanguins
                        </li>
                    </ul>
                    <a routerLink="/services-2" class="default-btn"
                        >En savoir plus<span></span
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Services Area -->

<!-- Start About Area -->
<section class="about-area ptb-100 pt-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/img.png" alt="image" />
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">A propos de nous</span>
                    <h2>Nous sommes là pour vous</h2>
                    <p>
                        Grâce à nos 10 ans d'expérience et notre savoir-faire,
                        Express SRH devenus l'acteur incontournable du transport
                        des documents importants, des colis et des produits
                        dédié au milieu médical et pharmaceutique. Express SRH
                        garantit des services de livraison efficaces et
                        ponctuels 24h/24 & 7j/7.
                    </p>
                    <ul class="features-list">
                        <li>
                            <span
                                ><i class="fas fa-check"></i>Livraison
                                rapide</span
                            >
                        </li>
                        <li>
                            <span
                                ><i class="fas fa-check"></i>Livraison en zone
                                reculée</span
                            >
                        </li>
                        <li>
                            <span
                                ><i class="fas fa-check"></i>Suivi de
                                commande</span
                            >
                        </li>
                        <li>
                            <span
                                ><i class="fas fa-check"></i>Disponibilité
                                24/7</span
                            >
                        </li>
                        <li>
                            <span
                                ><i class="fas fa-check"></i>Transport
                                sécurisé</span
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img3">
        <img src="assets/img/shape/shape3.svg" alt="image" />
    </div>
    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
</section>
<!-- End About Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Quelles sont nos avantages ?</span>
            <h2>
                Express SRH propose des solutions adaptées et personnalisées
            </h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/solution3.png" alt="team" />
                    </div>
                    <div class="content">
                        <span
                            >Express SRH garantit une prestation 24 heures sur
                            24 pour répondre à vos besoins en matière de
                            livraison.</span
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/solution2.png" alt="team" />
                    </div>
                    <div class="content">
                        <span
                            >Grâce à notre présence locale, nous sommes en
                            mesure d'intervenir rapidement à tout moment.
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/solution1.png" alt="team" />
                    </div>
                    <div class="content">
                        <span
                            >Express SRH met à votre disposition du personnel
                            qualifié pour répondre à vos besoins.</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Why Choose Us Area -->
<section class="why-choose-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-content">
                    <span class="sub-title">Pourquoi nous choisir </span>
                    <h2>
                        Pourquoi nous choisir pour vos besoins de livraison ?
                    </h2>
                    <p>
                        Express SRH est une entreprise spécialisée dans le
                        domaine de la livraison, ce qui signifie que nous avons
                        l'expertise et l'expérience nécessaires pour vous offrir
                        un service de qualité, que ce soit pour des produits
                        professionnels, personnels et médicaux. Nous comprenons
                        l'importance d'une livraison rapide et respectons les
                        délais convenus. Vous pouvez avoir l'assurance que vos
                        produits seront livrés en temps voulu.
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-image">
                    <img src="assets/img/img4.jpg" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Start Subscribe Area -->
<section class="subscribe-area ptb-100 pt-0 bg-F4F7FC">
    <div class="container">
        <div
            class="subscribe-inner-area jarallax"
            data-jarallax='{"speed": 0.3}'
        >
            <div class="subscribe-content">
                <span class="sub-title">COMMENCEZ IMMÉDIATEMENT !</span>
                <h2>
                    Recevez uniquement les nouvelles mises à jour de cette
                    newsletter
                </h2>
                <form class="newsletter-form">
                    <input
                        type="email"
                        class="input-newsletter"
                        placeholder="Entrer votre Email"
                        name="EMAIL"
                        required
                        autocomplete="off"
                    />
                    <button type="submit">S'abonner</button>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->
