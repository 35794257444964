<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div
    class="portfolio-agency-banner pa-bg4 jarallax"
    data-jarallax='{"speed": 0.3}'
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container mt-80">
                <div class="banner-content text-center">
                    <h1>A propos de nous</h1>
                </div>
                <div class="page-title-content">
                    <ul>
                        <li><a routerLink="/">Acceuil</a></li>
                        <li>A propos de nous</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- About Area Two -->
<section class="about-area-two ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-image">
                    <img
                        src="assets/img/img5.jpg"
                        alt="image"
                        class="rounded-10"
                    />

                    <div class="solution-video">
                        <a
                            href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                            class="video-btn popup-youtube"
                        >
                            <i class="flaticon-play-button"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-content">
                    <div class="section-title text-start">
                        <span class="sub-title">A propos d'Express SRH</span>
                        <h2>Services de Transport et Logistics Médical</h2>
                        <p>
                            Express SRH est un prestataire de services de
                            transport et de logistique médicale spécialisé dans
                            la gestion de la chaîne d'approvisionnement pour les
                            fournitures et les équipements médicaux.
                        </p>
                    </div>
                    <div class="about-text">
                        <h4>Qui sommes-nous?</h4>
                        <p>
                            Nous sommes une entreprise spécialisée dans le
                            domaine des services de transport et de logistique
                            médicale. Notre mission est de fournir des solutions
                            de transport et de logistique sur mesure pour les
                            établissements de santé, les pharmacies et les
                            patients à domicile, afin de garantir que les
                            fournitures et les équipements médicaux essentiels
                            soient livrés en toute sécurité et en temps voulu.
                        </p>
                    </div>
                    <div class="about-text">
                        <h4>nos missions?</h4>
                        <p>
                            nos missions sont d'offrir des services de transport
                            et de logistique de haute qualité, personnalisés et
                            innovants pour garantir que les fournitures et les
                            équipements médicaux essentiels arrivent à
                            destination en toute sécurité et en temps voulu.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area Two -->

<!-- Start Fun Facts Area -->
<section class="fun-facts-two pt-100 pb-70 bg-f2f2f7">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="fun-fact-card">
                    <i class="bx bx-list-check"></i>
                    <h3>
                        <span class="odometer" data-count="524">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Colis Livrés</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="fun-fact-card">
                    <i class="bx bx-smile"></i>
                    <h3>
                        <span class="odometer" data-count="560">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Clients Satisfaits</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="fun-fact-card">
                    <i class="bx bx-user-plus"></i>
                    <h3>
                        <span class="odometer" data-count="128">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Membres experts</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->

<!-- About Us -->

<!-- End About Us -->

<!-- Start Solution Area -->
<!-- End Solution Area -->

<!-- Start Feedback Area -->
<!-- End Feedback Area -->

<!-- Start Team Area -->
<section class="team-area pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">NOTRE ÉQUIPE</span>
            <h2>Notre équipe d'experts</h2>
        </div>

        <div class="team-slider owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team10.jpg" alt="team" />

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>John Smith</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team11.jpg" alt="team" />

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Lucy Eva</h3>
                    <span>Backend Team Leader</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team12.jpg" alt="team" />

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Steven Smith</h3>
                    <span>Frontend Team Leader</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team13.jpg" alt="team" />

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Steven Smith</h3>
                    <span>Web Developer</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team14.jpg" alt="team" />

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Williams Smith</h3>
                    <span>Web Developer</span>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.svg" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.png" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape5.png" alt="image" />
    </div>
    <div class="shape-img6">
        <img src="assets/img/shape/shape6.png" alt="image" />
    </div>
    <div class="shape-img9">
        <img src="assets/img/shape/shape9.png" alt="image" />
    </div>
    <div class="shape-img10">
        <img src="assets/img/shape/shape10.png" alt="image" />
    </div>
</section>
<!-- End Team Area -->

<!-- Start Subscribe Area -->
<section class="free-trial-area ptb-100 bg-f4f7fe">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="ft-img">
                    <img src="assets/img/img2.jpg" alt="Image" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="subscribe-content">
                    <span class="sub-title">COMMENCEZ IMMÉDIATEMENT ! </span>
                    <h2>
                        Recevez uniquement les nouvelles mises à jour de cette
                        newsletter
                    </h2>
                    <form class="newsletter-form">
                        <input
                            type="email"
                            class="input-newsletter"
                            placeholder="Entrer votre email"
                            name="EMAIL"
                            required
                            autocomplete="off"
                        />
                        <button type="submit">Inscrivez vous</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Partner</span>
            <h2>Ils Nous Font Confiance</h2>
        </div>

        <div class="row align-items-center">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img
                        src="assets/img/partner-image/partner6.png"
                        alt="image"
                    />
                    <img
                        src="assets/img/partner-image/partner6.png"
                        alt="image"
                    />
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img
                        src="assets/img/partner-image/partner7.png"
                        alt="image"
                    />
                    <img
                        src="assets/img/partner-image/partner7.png"
                        alt="image"
                    />
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img
                        src="assets/img/partner-image/partner8.png"
                        alt="image"
                    />
                    <img
                        src="assets/img/partner-image/partner8.png"
                        alt="image"
                    />
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img
                        src="assets/img/partner-image/partner9.png"
                        alt="image"
                    />
                    <img
                        src="assets/img/partner-image/partner9.png"
                        alt="image"
                    />
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img
                        src="assets/img/partner-image/partner10.png"
                        alt="image"
                    />
                    <img
                        src="assets/img/partner-image/partner10.png"
                        alt="image"
                    />
                </a>
            </div>
        </div>
    </div>

    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.svg" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.png" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape5.png" alt="image" />
    </div>
</section>
<!-- End Partner Area -->
