<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div
    class="portfolio-agency-banner pa-bg3 jarallax"
    data-jarallax='{"speed": 0.3}'
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container mt-80">
                <div class="banner-content text-center">
                    <h1>Nos Services</h1>
                </div>
                <div class="page-title-content">
                    <ul>
                        <li><a routerLink="/">Acceuil</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"
                            ><img src="assets/img/service1.jpg" alt="image"
                        /></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta"></div>
                        <h3>
                            <a routerLink="/blog-details"
                                >Transport de sang et d'échantillons biologiques
                            </a>
                        </h3>
                        <p>
                            Nous offrons des services de transport sécurisés
                            pour vos prélèvements biologiques et échantillons de
                            diagnostic
                        </p>
                        <a routerLink="/blog-details" class="read-more-btn"
                            >Read More <i class="flaticon-right-arrow"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"
                            ><img src="assets/img/service2.jpg" alt="image"
                        /></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta"></div>

                        <h3>
                            <a routerLink="/blog-details"
                                >Service de livraison de médicaments</a
                            >
                        </h3>
                        <p>
                            Nous proposons un service de livraison de
                            médicaments disponible 24h/24 et 7j/7, avec ou sans
                            ordonnance.
                        </p>
                        <a routerLink="/blog-details" class="read-more-btn"
                            >Read More <i class="flaticon-right-arrow"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"
                            ><img src="assets/img/service3.jpeg" alt="image"
                        /></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta"></div>

                        <h3>
                            <a routerLink="/blog-details"
                                >Transport de matériel médical</a
                            >
                        </h3>
                        <p>
                            Nous assurons le transport de vos équipements
                            médicaux avec sécurité et fiabilité
                        </p>
                        <a routerLink="/blog-details" class="read-more-btn"
                            >Read More <i class="flaticon-right-arrow"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"
                            ><img src="assets/img/service4.jpeg" alt="image"
                        /></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta"></div>
                        <h3>
                            <a routerLink="/blog-details"
                                >Gestion de stockage</a
                            >
                        </h3>
                        <p>
                            Notre service de stockage professionnel assure une
                            gestion efficace des arrivages et une optimisation
                            de vos stocks pour minimiser les pertes et réduire
                            les coûts.
                        </p>
                        <a routerLink="/blog-details" class="read-more-btn"
                            >Read More <i class="flaticon-right-arrow"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"
                            ><img src="assets/img/service5.jpg" alt="image"
                        /></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta"></div>

                        <h3>
                            <a routerLink="/blog-details"
                                >expédition des commandes</a
                            >
                        </h3>
                        <p>
                            Notre objectif est de fournir un processus de
                            préparation de commandes fluide, précis et rapide
                            pour répondre à vos besoins en fournitures
                            médicales.
                        </p>
                        <a routerLink="/blog-details" class="read-more-btn"
                            >Read More <i class="flaticon-right-arrow"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"
                            ><img src="assets/img/service6.jfif" alt="image"
                        /></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta"></div>

                        <h3>
                            <a routerLink="/blog-details">Transport rapide</a>
                        </h3>
                        <p>
                            Notre service de livraison finale s'assure que votre
                            équipement médical est livré directement chez le
                            patient. Nous offrons une optimisation logistique
                            complète
                        </p>
                        <a routerLink="/blog-details" class="read-more-btn"
                            >Read More <i class="flaticon-right-arrow"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/" class="prev page-numbers"
                        ><i class="fas fa-angle-double-left"></i
                    ></a>
                    <a routerLink="/" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page"
                        >2</span
                    >
                    <a routerLink="/" class="page-numbers">3</a>
                    <a routerLink="/" class="page-numbers">4</a>
                    <a routerLink="/" class="next page-numbers"
                        ><i class="fas fa-angle-double-right"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->
